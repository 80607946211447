import React from 'react';
import Layout from '../components/Layout';
import Helmet from 'react-helmet';

const ThanksPage = ({ data }) => {
	return (
		<Layout>
			<Helmet>
				<title>GridZeroSolar | Thank You!</title>
			</Helmet>
			<div
				style={{
					minHeight: 'calc(100vh - 3rem)',
					display: 'flex',
				}}
			>
				<div style={{ margin: 'auto', textAlign: 'center' }}>
					<h1>Submission Complete</h1>
					<p>We will get back to you as soon as possible.</p>
				</div>
			</div>
		</Layout>
	);
};

export default ThanksPage;
